import moment from 'moment';
import React, { Component, Fragment } from 'react';
import cn from "classnames";
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ArrowRightIcon, PlayIcon, PauseIcon, ClockIcon, ExclamationIcon, SaveIcon, TrashIcon, PaperClipIcon, ExclamationCircleIcon, ChevronUpIcon, ArrowLeftIcon, PencilAltIcon, ArrowDownIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { Menu, Transition } from '@headlessui/react';
import WizardModal from '../moduleFiles/wizardModal';
import NewCampaignWizard from '../moduleFiles/newCampaignWizard';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';

class OrderDrafts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orders: [],
            orderBy: { id: 0, name: "Filter by order" },
            order: {},
            meta: {},
            wizards: [],
            create_wizard: false,
            rename_wizard: false,
            limit: 10,
            page: 1,
            sort: "created",
            direction: "descending",
            status: { name: "draft", value: "draft" },
            remove_related_campaigns: { id: 1, name: "Don't remove already created campaigns", value: false },
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'created', value: 'created', min_width: '250px' },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'channels', value: 'channels', min_width: '250px' }
            ],
            stats: [
                { id: 1, name: "Draft", value: "draft", link: "/v2/" },
                { id: 2, name: "Pending", value: "pending" },
                { id: 3, name: "Error", value: "error" }
            ],
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            client: {},
            disabled: false,
            loader: false,
            wizard_name: "",
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
        };
    }

    componentWillMount() {

    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.query();
        this.props.updateStatistics();

        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        query: async () => {
            return new Promise(async (resolve) => {
                let status = { name: "draft", value: "draft" };
                this.state.stats.map((item) => {
                    if (window.location.pathname.indexOf(item.value) !== -1) {
                        status = item;
                    }
                })
                await this.promisedSetState({
                    status: status ? status : this.state.status
                });
                await this.functions.wizards(true);
                resolve();
            });
        },
        wizards: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({ loading: true });
                try {
                    let response = await this.calls.drafts();
                    await this.promisedSetState({
                        order: response.data,
                        meta: response.meta,
                        client: response.client,
                        wizards: response.data.drafts
                    });
                    if (this.props.setOrder) {
                        this.props.setOrder(this.state.order);
                    }
                } catch (error) { }
                await this.promisedSetState({ loading: false });
                resolve();
            });
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        createWizard: async (schedule = false) => {
            await this.promisedSetState({ loading_wizard: true });
            try {
                let response = await this.calls.createWizard({
                    order: this.state.order.id,
                    name: this.state.wizard_name,
                    schedule: schedule,
                    ...(this.state.order.budgetSplit && this.state.order.budgetSplit.value && { bingSync: this.state.order.budgetSplit.value })
                });
                this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        createWizardFromTemplate: async (data, schedule = false) => {
            await this.promisedSetState({ loading_wizard: true, create_wizard: false });
            try {
                let response = await this.calls.createWizard({
                    order: this.state.order.id,
                    name: this.state.wizard_name,
                    template_new: data,
                    schedule: schedule,
                    ...(this.state.order.budgetSplit && this.state.order.budgetSplit.value && { bingSync: this.state.order.budgetSplit.value })
                });
                this.props.history.push("/v2/campaigns/new/channels?draft=" + response.data.id);
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        clone: async (wizard) => {
            this.state.wizards.map((item) => { if (item.id === wizard.id) { item.loading = true } return item });
            await this.promisedSetState({ wizards: this.state.wizards });
            try {
                let response = await this.calls.clone(wizard.id);
                let cloned_wizard = JSON.parse(JSON.stringify(wizard));
                cloned_wizard.id = response.data.id;
                cloned_wizard.loading = false;
                this.state.wizards.unshift(cloned_wizard);
                this.state.wizards.map((item) => { if (item.id === wizard.id) { item.loading = false } return item });
                await this.promisedSetState({ wizards: this.state.wizards });
            } catch (error) {
                this.state.wizards.map((item) => { if (item.id === wizard.id) { item.loading = false } return item });
                await this.promisedSetState({ wizards: this.state.wizards });
            }
            this.props.updateStatistics();
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.wizards.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ wizards: this.state.wizards });
            try {
                await this.calls.remove(temp.id, this.state.remove_related_campaigns.value);
                await this.promisedSetState({ wizards: this.state.wizards.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.wizards.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ wizards: this.state.wizards });
            }
            await this.promisedSetState({ remove_related_campaigns: { id: 1, name: "Don't remove already created campaigns", value: false } });
            this.props.updateStatistics();
        },
        rename: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.rename_wizard));
            await this.promisedSetState({ rename_wizard: false });
            this.state.wizards.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ wizards: this.state.wizards });
            try {
                await this.calls.renameWizard({ id: temp.id, name: temp.name });
                this.state.wizards.map((item) => { if (item.id === temp.id) { item.name = temp.name; item.loading = false } return item });
                await this.promisedSetState({ wizards: this.state.wizards });
            } catch (error) {
                this.state.wizards.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ wizards: this.state.wizards });
            }
        },
        menu: (option, row) => {
            if (option === "Open") {
                this.props.history.push("/v2/campaigns/new/channels?draft=" + (row.id ? row.id : row._id));
            } else if (option === "Delete") {
                this.setState({
                    remove: row
                });
            } else if (option === "Rename") {
                this.setState({
                    rename_wizard: row
                });
            } else if (option === "Clone") {
                this.functions.clone(row);
            } else if (option === "Preview") {
                window.open(window.location.protocol + "//" + window.location.host + "/preview/v2/" + row.id, "_blank");
            }
        }
    };

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        drafts: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderDrafts?id=" + this.functions.getIdFromPath() + "&limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&status=" + this.state.status.value;
            return apiRegister.call(options, url);
        },
        createWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createWizard";
            return apiRegister.call(options, url);
        },
        clone: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/cloneWizard?id=" + id;
            return apiRegister.call(options, url);
        },
        renameWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateWizardName?id=" + data.id;
            return apiRegister.call(options, url);
        },
        remove: (id, query = false) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeWizard?id=" + id + (query ? "&skip_remove=true" : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "new") {
                                this.setState({
                                    create_wizard: true,
                                    wizard_name: this.state.order.name
                                })
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.wizards();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.wizards();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.wizards();
                        }}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        hideUserDropdown={true}
                        breadcrumb={"Drafts"}
                        buttons={[
                            { text: "Create new", icon: PencilAltIcon, value: "new", loading: false, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                    />
                </div>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    dropDown={true}
                    dropDownOptions={[
                        { id: 1, name: "Don't remove already created campaigns", value: false },
                        { id: 2, name: "Remove created campaigns", value: true }
                    ]}
                    dropDownSelectedRender={this.state.remove_related_campaigns}
                    onDropDownSelect={async(value) => {
                        await this.promisedSetState({ remove_related_campaigns: value });
                    }}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/* SLIDEOUT NEW DRAFT */}
                <SlideoutTailwind
                    title={"New Draft"}
                    submitButtonText={"Create"}
                    medium={true}
                    secondaryButton={false}
                    open={this.state.create_wizard}
                    onCancel={() => {
                        this.promisedSetState({ create_wizard: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({ loader: true });
                        this.refs.newCampaignwizard.functions.create();
                    }}
                    loader={this.state.loader}

                    disabled={this.state.disabled}
                >
                    {
                        this.state.create_wizard &&
                        <NewCampaignWizard
                            ref="newCampaignwizard"
                            order={this.state.order}
                            client={this.state.client}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            input={this.state.wizard_name}
                            onNewDraft={async (value, data = false) => {
                                await this.promisedSetState({ wizard_name: value });
                                this.functions.createWizard(data);
                            }}
                            onNewDraftFromTemplate={(value, data = false) => {
                                this.functions.createWizardFromTemplate(value, data);
                            }}

                        />

                    }

                </SlideoutTailwind>

                {/* OLD CREATION
                <WizardModal
                    title={"New draft"}
                    text={"Set Name below"}
                    button={"Create"}
                    icon={ClipboardListIcon}
                    open={this.state.create_wizard}
                    input={this.state.wizard_name}
                    onLoading={this.state.loading_wizard}
                    onInput={(value) => {
                        this.setState({
                            wizard_name: value
                        })
                    }}
                    onCreate={() => {
                        this.functions.createWizard();
                    }}
                    onClose={() => {
                        this.setState({
                            create_wizard: false
                        })
                    }}
                />
                */}

                <WizardModal
                    title={"Rename"}
                    text={"Update name below"}
                    button={"Update"}
                    icon={PencilAltIcon}
                    open={this.state.rename_wizard ? true : false}
                    input={this.state.rename_wizard ? this.state.rename_wizard.name : ""}
                    onLoading={this.state.loading_wizard}
                    onInput={(value) => {
                        this.state.rename_wizard.name = value;
                        this.setState({
                            rename_wizard: this.state.rename_wizard
                        })
                    }}
                    onCreate={() => {
                        this.functions.rename();
                    }}
                    onClose={() => {
                        this.setState({
                            rename_wizard: false
                        })
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading drafts ...</div>
                    </div>
                }

                {/* TABLE */}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full h-full overflow-hidden">

                            {/*NO WIZARDS*/}
                            {
                                this.state.wizards.length < 1 &&
                                <div className="border-t border-gray-200 h-full flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No {this.state.status.value !== "draft" ? this.state.status.value : ""} drafts available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.wizards.length > 0 &&
                                <div className="relative overflow-x-auto table-overflow h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase"></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                await this.promisedSetState({
                                                                    page: 1,
                                                                    sort: item.value,
                                                                    direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                })
                                                                // THIS IS CAUSING PAGE TO RELOAD !!!
                                                                this.functions.wizards();
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(this.state.sort === item.value ? "bg-gray-100 bg-opacity-75" : "hover:bg-gray-100 hover:bg-opacity-75") + " px-6 py-3 border-gray-300 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                <div className="flex flex-col">
                                                                    <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                    <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                    {/* <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} /> */}
                                                                </div>
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.wizards.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr key={item.id} className="border-b">
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative w-10 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className={`border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                {/* <Menu.Items className={`${this.state.wizards.length - (index + 1) < 5 && this.state.wizards.length > 5 ? "bottom-100 left-100" : ""} border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}> */}
                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Open", "Rename", "Clone", "Preview", "Delete"].filter((item) => {
                                                                                            if (item === "Preview" || item === "Clone" || item === "Rename") {
                                                                                                return this.state.status.value !== "pending" && this.state.status.value !== "error"
                                                                                            } else if (item === "Open") {
                                                                                                return this.state.status.value !== "pending"
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    if (this.state.status.value !== "pending") {
                                                                        this.functions.menu("Open", item);
                                                                    }
                                                                }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'created' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.created ? moment(item.created).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                ["bg-purple-100 text-purple-500"]: item.status === "upcoming",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "archived" || item.status === "ended",
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "pending",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "draft",
                                                                                ["bg-red-100 text-red-600"]: item.status === "error",
                                                                            })
                                                                        }>
                                                                        {item.status ? item.status : "-"}
                                                                    </span>
                                                                </td>
                                                                <td className={(this.state.sort === 'channels' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        {((item.status !== "draft" && item.channel === "facebook") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["facebook"])) &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "linkedin") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["linkedin"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "google") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["google"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "tiktok") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["tiktok"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "snapchat") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["snapchat"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "twitter") || ((item.status === "draft" || item.status === "error") && item.channels && item.channels["twitter"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.status !== "draft" &&
                                                                            <div className="ml-3 font-medium">
                                                                                {item.channel}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {
                                false &&
                                this.state.wizards.length > 0 &&
                                <div className="relative overflow-scroll h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th scope="col" className="cursor-pointer transition-all duration-200 hover:bg-gray-100 px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase"></th>
                                                {this.state.columns.map((item, index) => {
                                                    let last = (this.state.columns.length - 1) === index;
                                                    return (
                                                        <th
                                                            onClick={() => {
                                                                this.setState({
                                                                    sort: item.value,
                                                                    direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                })
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(!last ? " " : "") + "px-6 py-3 border-gray-300 hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                <div className="flex flex-col">
                                                                    <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                    <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.wizards.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr key={item.id} className="border-b">
                                                                <td style={{ whiteSpace: "nowrap" }} className={"px-4 sm:px-6 py-4 flex flex-row border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative w-10 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {
                                                                                        ["Open", "Preview", "Clone", "Delete"].filter((item) => {
                                                                                            if (item === "Open" || item === "Preview" || item === "Clone") {
                                                                                                return this.state.status.value !== "pending"
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.functions.menu("Open", item);
                                                                }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-semibold cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                ["bg-purple-100 text-purple-500"]: item.status === "upcoming",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "archived" || item.status === "ended",
                                                                                ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "paused",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "pending",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "draft",
                                                                                ["bg-red-100 text-red-600"]: item.status === "error",
                                                                            })
                                                                        }>
                                                                        {item.status ? item.status : "-"}
                                                                    </span>
                                                                </td>
                                                                <td className={(this.state.sort === 'channels' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        {((item.status !== "draft" && item.channel === "facebook") || (item.status === "draft" && item.channels && item.channels["facebook"])) &&
                                                                            <div
                                                                                className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "linkedin") || (item.status == "draft" && item.channels && item.channels["linkedin"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "google") || (item.status == "draft" && item.channels && item.channels["google"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "tiktok") || (item.status == "draft" && item.channels && item.channels["tiktok"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "snapchat") || (item.status == "draft" && item.channels && item.channels["snapchat"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {((item.status !== "draft" && item.channel === "twitter") || (item.status == "draft" && item.channels && item.channels["twitter"])) &&
                                                                            <div
                                                                                className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.status !== "draft" &&
                                                                            <div className="ml-3 font-medium">
                                                                                {item.channel}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default OrderDrafts;